<template>
  <div class="commonBread">
    所在位置：
    <el-breadcrumb separator=">">
      <el-breadcrumb-item 
        v-for="(item, index) in dataList" 
        :key="index"  
        :to="item.url || null"
      >{{ item.title }}</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  props: ['dataList'],
}
</script>

<style lang='scss'>
  .commonBread {
    display: flex;
    font-size: 14px;
    color: #909399;
    padding: 20px 0;
    align-items: center;
  }
</style>