<template>
  <div class="standard_data">
    <img :src="detail.picture" />
    <div>
      <div style="min-height: 154px;">
        <h3>
          <p>{{ detail.standardNumber }}</p>
          <span :style="{
            backgroundColor: detail.standardState == 'ST' ? '#25B392' : detail.standardState == 'NST' ? '#feae18' : '#e34742'
          }">{{ detail.standardState  == 'ST' ? '现行' : detail.standardState == 'NST' ? '未现行' : '废止' }}</span>
        </h3>
        <h5>{{ detail.chineseName }}</h5>
        <p>{{ detail.englishName }}</p>
      </div>
      <div>
        <el-button v-if="detail.onlineRead" type="primary" color="#0072EF" @click="eventClick('read')"><svg-icon icon-class="online-read"></svg-icon>在线试读</el-button>
        <el-button v-if="detail.buyPattern == 0 || detail.buyPattern == 2" plain type="primary" color="#0072EF" @click="visible = true"><svg-icon icon-class="shopping-cart"></svg-icon>标院购书</el-button>
        <el-button v-if="detail.buyPattern == 1 || detail.buyPattern == 2" type="danger" color="#F02818" plain @click="eventClick"><svg-icon icon-class="shopping-cart"></svg-icon>京东购书</el-button>
        <el-button v-if="!detail.buyPattern && detail.buyPattern != 0" type="info">暂不销售</el-button>
      </div>
    </div>
    <BuyModal :visible="visible" @close="visible = false"></BuyModal>
  </div>
</template>

<script>
import BuyModal from './BuyModal'
export default {
  props: ['detail'],
  components: {
    BuyModal
  },
  data() {
    return {
      visible: false
    }
  },
  methods: {
    eventClick(type) {
      if(type == 'read') window.open(this.detail.readUrl)
      else window.open(this.detail.buyUrl)
    }
  }
}
</script>

<style lang='scss'>
  .standard_data {
    display: flex;

    & > img {
      width: 146px;
      height: 204px;
      background: rgba(0,0,0,0.00);
      border: 1px solid #ebebeb;
      border-radius: 2px;
      margin-right: 25px;
    }

    & > div {
      width: 889px;

      & > div {
        padding: 5px 10px;
        width: 889px;

        & > h3 {
          line-height: 46px;
          font-size: 32px;
          color: #303133;
          margin-bottom: 10px;
          display: flex;
          align-items: center;

          & > p {
            width: 807px;
            margin-right: 10px;
            flex-shrink: 0;
          }

          & > span {
            width: 72px;
            height: 24px;
            border-radius: 2px;
            display: block;
            font-size: 14px;
            text-align: center;
            color: #ffffff;
            line-height: 24px;
            flex-shrink: 0;
          }
        }

        & > h5 {
          line-height: 26px;
          font-size: 18px;
          color: #303133;
          margin-bottom: 10px;
        }

        & > p {
          line-height: 22px;
          font-size: 16px;
          color: #606266;
        }

        .el-button {
          height: 40px;
          width: 121px;
          font-size: 14px;

          .svg-icon {
            width: 20px;
            height: 20px;
            margin-right: 5px;
          }
        }
      }
    }
  }
</style>