<template>
  <div class="underline_show">
    <div v-for="item in data" :key="item.key">
      <span>{{ item.title }}</span>
      <span>{{ item.title ? detail[item.key] || '-' : '' }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ['data', 'detail']
}
</script>

<style lang='scss'>
  .underline_show {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;

    & > div {
      padding: 10px;
      border-bottom: 1px dashed #ebebeb;
      display: flex;
      width: 50%;

      & > span {
        &:nth-child(1) {
          font-size: 14px;
          color: #999999;
          line-height: 20px;
          display: block;
          width: 100px;
          flex-shrink: 0;
          margin-right: 10px;
        }

        &:nth-child(2) {
          font-size: 14px;
          color: #333333;
          line-height: 20px;
        }
      }
    }

  }
</style>