<template>
  <el-dialog 
    v-model="visible"
    :width="400"
    title=""
    custom-class="buy_modal"
    :append-to-body="true"
    @close="$emit('close')">
    <div class="panel_one">
      <h4>{{ detail.invoiceTitle }}</h4>
      <p>联系电话：{{ detail.buyPhone }}</p>
    </div>
    <el-divider />
    <div class="panel_two">
      <img src="../../../assets/img/qq.png" />
      <div>
        <h5>联系QQ</h5>
        <p>QQ号：{{ detail.qqNumber }}</p>
      </div>
      <el-button type="primary" plain color="#0072EF" @click="eventClick">立即联系</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean
    }
  },
  data() {
    return {
      detail: {}
    }
  },
  mounted() {
    this.showPage()
  },
  methods: {
    showPage() {
      this.$API.Relation().then(res => {
        if(res.data.code == 200) {
          this.detail = res.data.data
        }
      })
    },
    eventClick() {
      window.open(this.detail.qqUrl)
    }
  }
}
</script>

<style lang='scss'>
  .buy_modal {
    .panel_one {
      padding: 10px;

      & > h4 {
        font-size: 16px;
        color: #1d2129;
        line-height: 22px;
        margin-bottom: 5px;
      }

      & > p {
        font-size: 14px;
        color: #606266;
        line-height: 20px;
      }
    }

    .el-divider {
      border-color: #DCDFE6;
      margin: 10px 0 20px;
    }

    .panel_two {
      display: flex;
      padding: 10px;
      align-items: center;

      & > img {
        width: 36px;
        height: 36px;
        margin-right: 15px;
      }

      & > div {
        width: 204px;
        margin-right: 12px;

        & > h5 {
          font-size: 16px;
          color: #1d2129;
          line-height: 22px;
          margin-bottom: 5px;
        }

        & > p {
          font-size: 12px;
          color: #909399;
          line-height: 18px;
        }
      }

      .el-button {
        width: 80px;
        height: 36px;
        border-radius: 4px;
      }
    }
  }
</style>