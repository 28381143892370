<template>
  <div id="standardDetail" v-wechat-title="detail.standardNumber + ' - 社会团体标准化专业人员公共服务平台'">
    <CommonBread :dataList="dataList"></CommonBread>
    <div class="content">
      <StandardData :detail="detail"></StandardData>
      <el-divider />
      <UnderlineShow :data="data" :detail="detail"></UnderlineShow>
      <div class="w-e-text" v-html="detail.other"></div> 
    </div>
  </div>
</template>

<script>
import CommonBread from '@/components/CommonBread'
import UnderlineShow from './components/UnderlineShow'
import StandardData from './components/StandardData'
export default {
  components: {
    CommonBread,
    UnderlineShow, 
    StandardData, 
  },
  data() {
    return {
      dataList: [
        { title: '标准', url: '/knowledgeBase/STANDARD' },
        { title: '详情' },
      ],
      detail: {},
      data: [
        { title: '发布日期', key: 'publishedDate' },
        { title: '归口单位', key: 'attributeUnit' },
        { title: '实施日期', key: 'implementDate' },
        { title: '执行单位', key: 'performUnit' },
        { title: '中国标准分类号', key: 'ccs' },
        { title: '主管部门', key: 'competentDepartment' },
        { title: '国际标准分类号', key: 'ics' },
        { title: '', key: '' },
      ]
    }
  },
  mounted() {
    this.showPage()
  },
  methods: {
    showPage() {
      this.$API.StandardDetail({
        id: parseInt(this.$route.params.id)
      }).then(res => {
        if(res.data.code == 200) {
          res.data.data.publishedDate = res.data.data.publishedDate && res.data.data.publishedDate.split(' ')[0] || null
          res.data.data.implementDate = res.data.data.implementDate.split(' ')[0]
          res.data.data.picture = res.data.data.picture ? this.baseUrl + res.data.data.picture : require('@/assets/img/nocover.png')
          this.detail = res.data.data;
        }
      })
    },
  }
}
</script>

<style lang='scss'>
  #standardDetail {
    width: 1200px;
    padding: 0 10px;
    margin: 0 auto 30px;

    .content {
      background: #ffffff;
      border: 1px solid #ebebeb;
      border-radius: 10px;
      padding: 40px 50px;

      .el-divider {
        margin: 40px 0 20px;
      }
    }
  }
</style>